import { ApplicationRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Modal, ModalActionType } from 'components/modal/modal.model';
import { Subject, Subscription, concat, interval } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { isChrome, isEdge, isFirefox } from 'weavix-shared/utils/utils';

import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { LocalDeviceUtility } from '@weavix/domain/src/utils/local-device-utility';
import { SnackBarComponent } from 'components/snack-bar/snack-bar.component';
import { filter } from 'rxjs/operators';
import { AlertService } from 'weavix-shared/services/alert.service';
import { PttService } from 'weavix-shared/services/ptt.service';
import { AnalyticsService } from './core/services/analytics.service';
import { AppService } from './core/services/app.service';
import { TranslationService } from './core/services/translation/translation.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    appLoading: boolean;
    private destroyed$: Subject<boolean> = new Subject();
    private routeData$: Subscription;

    pwaModal: Modal;
    permissionModal: Modal;
    pwaPrompt;

    unauthenticated: () => void;
    initialized: boolean;

    constructor(
        private translationService: TranslationService,
        private router: Router,
        private route: ActivatedRoute,
        private matSnackBar: MatSnackBar,
        private alertsService: AlertService,
        private swUpdate: SwUpdate,
        private appRef: ApplicationRef,
        private appService: AppService,
        private pttService: PttService,
    ) {

        this.pttService.prepare();
        this.appService.setAppDetails(fn => {
            this.unauthenticated = fn;
            this.checkLogin();
        });
        this.translationService.setInitialLanguage();
        this.router.events.subscribe(async event => {
            if (event instanceof NavigationEnd) {
                let r = this.route.firstChild;
                while (r) {
                    if (r.firstChild) {
                        r = r.firstChild;
                    } else {
                        break;
                    }
                }
                if (this.routeData$) this.routeData$.unsubscribe();
                this.routeData$ = r.data.subscribe(d => {
                    AnalyticsService.page(d.prettyName);
                });
            }
        });

        if (this.swUpdate.isEnabled) {
            this.swUpdate.versionUpdates
                .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
                .subscribe(async () => {
                    if (document.cookie.indexOf('e2e=true') !== -1) return;
                    this.alertsService.sendPrompt('pwa.update-title', 'pwa.update-refresh')
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe(async () => {
                        await this.swUpdate.activateUpdate();
                        document.location.reload();
                    });
                });

            const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
            concat(appIsStable$, interval(3 * 60 * 60 * 1000)).subscribe(() => this.swUpdate.checkForUpdate());
        }

        LocalDeviceUtility.denied$.subscribe(() => {
            this.permissionModal = {
                isOpen: true,
                width: 600,
                actions: {
                    submit:  { show: true, textKey: 'generics.ok' },
                },
                fullScreen: false,
                content: true,
            };
        });
    }

    permissionClose() {
        this.permissionModal = null;
    }

    get browserError() {
        if (isChrome()) return 'meeting-join.denied-chrome';
        if (isFirefox()) return 'meeting-join.denied-firefox';
        if (isEdge()) return 'meeting-join.denied-edge';
        return null;
    }

    async ngOnInit() {
        this.appLoading = false;
        this.openAlertSubscription();
        this.openAppLoadingSubscription();

        this.initialized = true;
        this.checkLogin();
    }

    pwaClose(action: ModalActionType) {
        this.pwaModal = null;
        localStorage.setItem('pwa-prompted', 'true');
        if (action === ModalActionType.submit) {
            this.pwaPrompt.prompt();
        }
    }

    checkLogin() {
        if (this.initialized && this.unauthenticated) {
            this.pwaModal = null;
            this.unauthenticated();
        }
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
        if (this.routeData$) this.routeData$.unsubscribe();
    }

    private openAlertSubscription(): void {
        this.alertsService.alert$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
                if (this.unauthenticated) return;
                const sb = res.icon ?
                this.matSnackBar.openFromComponent(SnackBarComponent, {
                    duration: res.timeout,
                    verticalPosition: 'bottom',
                    panelClass: res.cssClass,
                    data: res,
                }) : this.matSnackBar.open(
                    res.message,
                    res.action, {
                        duration: res.timeout,
                        verticalPosition: 'bottom',
                        panelClass: res.cssClass,
                });
                if (!res.response) return;
                sb.afterDismissed()
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe(() => {
                        res.response.next();
                });
            });
    }

    private openAppLoadingSubscription(): void {
        AlertService.appLoadingSubject
            .pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
                this.appLoading = res;
            });
    }
}
