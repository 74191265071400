// edit the vars below as needed during local development
const devBackend = true;
const devCcBackend = true;
const radioSsl = true;
const consoleSsl = true;
const ccSsl = true;

const ssl = document.location.href.startsWith('https://');
export function getLocalPubSubPort(): number {
    return devBackend ? 443 : ssl ? 3443 : 3000;
}

export function getLocalSiblingUrl(subDomain: string): string {
    if (subDomain === 'app') {
        return 'http://localhost:4200';
    } else if (subDomain === 'radio') {
        return `${radioSsl ? 'https' : 'http'}://localhost:4201`;
    } else if (subDomain === 'console') {
        return `${consoleSsl ? 'https' : 'http'}://localhost:4202`;
    } else if (subDomain === 'api-controlcenter') {
        return devCcBackend ? 'https://api-controlcenter.weavixdev.com' : 
            ccSsl ? 'https://localhost:3100' : 'http://localhost:3100';
    } else if (devBackend) {
        return `https://${subDomain}.weavixdev.com`;
    } else if (subDomain === 'api') {
        return `${ssl ? 'https' : 'http'}://localhost:${ssl ? '3443' : '3000'}`;
    } else if (subDomain === 'mqtt') {
        return 'localhost';
    } else {
        throw new Error(`Unknown subdomain: ${subDomain}`);
    }
}
