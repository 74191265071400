import { FilterFolder, FolderType } from '../models/folder.model';
import { PermissionAction } from '../permissions/permissions.model';
import { css } from '../utils/css';
import { FEATURE_ICONS } from '../utils/feature.icons';
import { Company } from './company.model';
import { Craft } from './craft.model';
import { FormAction } from './forms.model';
import { AppIcon } from './icon.model';
import { Tag } from './tag.model';

export interface TableOptions {
    title: {
        string: string;
        centered?: boolean;
        fontSize?: number;
        paddingLeft?: number;
    };
    hasDefaultSort?: boolean;
    translate?: boolean;
    rowCount?: boolean;
    back?: boolean | (() => void);
    keyCol: string;
    columns: TableColumn[];
    columnGroups?: TableColumnGroup[];
    rowEdits: RowEdit[];
    canAdd?: boolean;
    canImport?: boolean;
    headerOptions?: TableHeaderOptions;
    tablePadding?: number;
    backgroundColor?: string;
    addOptions?: Array<{
        display: string;
        id: string;
        onClick: () => void
    }>;
    externalSearch?: {
        fields?: string[];
    };
    pagination?: {
        initPageSize?: number;
        showMoreSize?: number;
        pageUpdated?: ((shown: number, total: number) => void);
    };
    filters?: TableFilter[];
    select?: {
        selectable?: boolean;
        multiSelect?: boolean;
        showCheckboxes?: boolean;
        showCheckboxesWithEditPermission?: boolean;
        rowsClickable?: boolean; // if you want action on row click
        rowsDblClickable?: boolean;
        checkOnClick?: boolean;
    };
    format?: (row) => any;
    bulk?: {
        url: (id: string) => string;
        cache: string;
        customAction?: boolean; // Will emit a bulkMoveEdit event rather than use batch service
    };
    tableEdits?: TableEdit[];
    presetSearchQuery?: string;
    folderType?: FolderType;
    folderRowSuffix?: {
        show: boolean;
        icon?: AppIcon;
    };
    viewPermissionAction?: PermissionAction;
    editPermissionAction?: PermissionAction;
    facilityIds?: (row) => string[]; // for a given row, return what facilities it is part of for permission checks
    submitPermissionAction?: PermissionAction;
    facilityId?: string;
    routerLink?: ((row) => string);
    folderId?: string;
    minHeight?: number;
    deleteMessageKey?: string;
    noData?: {
        canAdd?: boolean;
        icon?: string;
        messageKey?: string;
        isContentTemplate?: boolean;
    };
    hideTableIntro?: boolean;
    hideColumnHeaders?: boolean;
    folderIsLink?: boolean;
    showStickyButtons?: boolean;
    rowsExpandContainer?: boolean;
    timeAgoColumnToggle?: boolean;
    csvExport?: boolean;
    canMoveRow?: (row) => boolean;
    disableRow?: (row) => boolean; // remove click action from specified rows
    locked?: (row) => boolean;
    onBack?: () => any;
    importKey?: string;
    duration?: number;
    settingsKey?: string;
    paginate?: {
        enabled: boolean;
        pageSize: number;
        loadMore?: ((limit: number, sortColumn?: TableColumn, direction?: PaginationDirection, reload?: boolean) => any);
        isLazyLoaded?: boolean;
    };
    isSubTable?: boolean;
    columnAlignmentOffset?: number; // if you need to offset the column headers for an icon in a table row
    showHeaderSeparator?: boolean;
    sideBarFilters?: {
        show?: boolean;
    };
    rowIcon?: (row) => AppIcon;
    rowBackgroundColor?: string;
    rowIconFixedWidth?: boolean;
    totalsRowConfig?: { show: boolean; };
    /**
     * Highlighted Rows will have an orange highlight on the left side of the row
     * @param highlightedRows A list of IDs to highlight (via keyCol)
     */
    highlightedRows?: string[];

    /**
     * A message to show at the bottom of all of the table rows, such as "no more data"
     */
    endMessageKey?: string;
    endMessageNgStyle?: {[cssPropName: string]: string | number};
}

export interface TableHeaderOptions {
    hide?: boolean;
    title?: string;
    isTitleVisible?: boolean;
    isBackIconVisible?: boolean;
    isPaginationInfoVisible?: boolean;
    hasColumnGroups?: boolean;
    actions?: TableHeaderAction[];
    reportActions?: TableHeaderReportAction[];
    search?: TableHeaderSearch;
    selectable?: boolean;
    bulkEdits?: BulkEdit[];
    folderType?: FolderType;
    editPermissionAction?: PermissionAction;
    facilityId?: string;
    facilityIds?: (row) => string[];
    import?: {
        name: string;
        action: () => void;
    };
}

export interface TableHeaderAction {
    name?: string;
    type?: TableHeaderActionType;
    icon?: string;
    disabled?: () => boolean;
    permission?: PermissionAction;
    facilityId?: string;
    clickAction?: () => void;
}

export interface TableHeaderReportAction {
    name?: string;
    type?: TableHeaderReportActionType;
    icon?: string;
    isShowLabel?: boolean;
    disabled?: () => boolean;
    permission?: PermissionAction;
    facilityId?: string;
}

export interface TableHeaderSearch {
    show?: boolean;
    icon?: string;
    placeholder?: string;
    fields?: (string | ((row) => any)) [];
    searchAction?: ((columnNames: (string | ((row) => any))[], value: string) => any);
}

export interface TableHeaderPaginationInfo {
    shown?: number;
    total?: number;
}


export enum TableHeaderActionType {
    Add = 'add',
    Delete = 'delete',
    Update = 'update',
    Import = 'import',
}

export enum TableHeaderReportActionType {
    Export = 'export',
}

export const actionTypeToTranslationKey: {[key in TableHeaderActionType]?: string} = {
    [TableHeaderActionType.Add]: 'table.header.add',
    [TableHeaderActionType.Delete]: 'table.header.delete',
    [TableHeaderActionType.Update]: 'table.header.update',
    [TableHeaderActionType.Import]: 'table.header.import',
};

export const reportActionTypeToTranslationKey: {[key in TableHeaderReportActionType]?: string} = {
    [TableHeaderReportActionType.Export]: 'table.header.report.export',
};

export interface TableExportData {
    keys?: string[];
    folderKeys?: string[];
    folderUrls?: { [folderKey: string]: string };
}

export interface TableRow {
    original: any;
    key: string;
    items: RowItem[];
    selected?: boolean;
    highlighted?: boolean;
    /**
     * Marked rows will have the left end highlighted in orange
     */
    marked?: boolean;
    /**
     * Set rowPrefix to true if any rows in a list are marked
     * this keeps spacing consistent
     */
    rowPrefix?: boolean;
    rowSuffix?: {
        icon: (row) => AppIcon;
        text: (row) => string;
    };
    edits?: RowEdit[];
    isFolder?: boolean;
    link?: string;
    clickable?: boolean;
    checkbox?: boolean;
    locked?: boolean;
    style?: any;
    subTable?: {
        show: boolean;
        options: TableOptions;
        rows: any[];
    };
    rowHeight?: boolean;
    icon?: AppIcon;
    iconFixedWidth?: boolean;
    class?: string;
    backgroundColor?: string;
}

export interface RowItemIcon {
    value: string;
    tooltip?: string;
    clickable?: boolean;
    color?: string;
}

export interface RowItem {
    value: any;
    tooltip?: any;
    sort?: any;
    colKey: string;
    minWidth?: number;
    maxWidth?: number;
    maxLines?: number;
    class?: string;
    clickable?: boolean;
    pending?: boolean;
    prefix?: AppIcon;
    postfix?: AppIcon;
    rowItemNgStyle?: {[key: string]: any};
    lineExpand?: (row: any) => any;
}
export interface SubRowItem extends RowItem {
    type: RowItemType; // sub row items have no column mapping, so we set their type (for meow)
}

export type RowEditFilter = (row: any, edit: RowEdit) => boolean;

export interface RowEdit {
    title: string;
    action: RowEditAction;
    icon: string;
    disabled?: boolean | ((row: any) => boolean);
    show?: (row: any) => boolean;
    invisible?: boolean;
}

export enum RowEditAction {
    view = 'view',
    edit = 'edit',
    copy = 'copy',
    delete = 'delete',
    register = 'register',
    print = 'print',
    propertyUpdate = 'propertyUpdate',
    enable = 'enable',
    exclusions = 'exclusions',
    disable = 'disable',
    fillout = 'fillout',
    generateReport = 'generateReport',
    checkoutEveryone = 'checkoutEveryone',
    redo = 'redo',
    cancel = 'cancel',
    channel = 'channel',
    goToMap = 'goToMap',
    move = 'move',
    logOut = 'logout',
    sms = 'sms',
    assign = 'assign',
    unassign = 'unassign',
    remove = 'remove',
    resend = 'resend',
    revoke = 'revoke',
    verify = 'verify',
    unblock = 'unblock',
    download = 'download',
}

export const commonRowEdits: { [key: string]: RowEdit} = {
    delete: {
        title: 'generics.delete',
        icon: 'fas fa-trash',
        action: RowEditAction.delete,
    },
    edit: {
        title: 'generics.edit',
        icon: 'fas fa-pencil-alt',
        action: RowEditAction.edit,
    },
    copy: {
        title: 'generics.copy',
        icon: 'fas fa-copy',
        action: RowEditAction.copy,
    },
};

export interface TableEdit {
    title: string;
    action: TableEditAction;
    icon: string;
    static?: boolean;
}

export enum TableEditAction {
    import = 'import',
    view = 'view',
    copy = 'copy',
    download = 'download',
}

export interface BulkEdit {
    title: string;
    message?: string;
    action: BulkEditAction;
    isReportAction?: boolean;
    icon: string;
    permission?: any;
}

export enum BulkEditAction {
    delete = 'delete',
    tag = 'tag',
    enable = 'enable',
    disable = 'disable',
    export = 'export',
    send = 'send',
    edit = 'edit',
    move = 'move',
}

export interface BulkEditEvent {
    keys: string[];
    folders: string[];
    action: BulkEditAction;
    parentFolderId?: string;
}

export enum TableFilterCategory {
    People = 'people',
    Companies = 'companies',
    Crafts = 'crafts',
    Groups = 'groups',
    Geofences = 'geofences',
    ItemTypes = 'itemTypes',
    Items = 'items',
    Walt = 'walt',
    Wilma = 'wilma',
    Cluster = 'cluster',
    Tags = 'tags',
    TypeBasic = 'typeBasic',
    TypeFull = 'typeFull',
    ActiveUsers = 'activeUsers',
    InvitedUsers = 'invitedUsers',
    RequestedUsers = 'requestedUsers',
    BlockedUsers = 'blockedUsers',
    ImportedUsers = 'importedUsers',
    Classifications = 'classifications',
    Sites = 'sites',
    Users = 'users',
    Type = 'type',
    Wisps = 'wisps',
    WispAssigned = 'wispAssigned',
    WispNotAssigned = 'wispNotAssigned',
    Badge = 'badge',
    BadgeAssigned = 'badgeAssigned',
    BadgeNotAssigned = 'badgeNotAssigned',
}

export enum FilterResultType {
    Person = 'person',
    Items = 'items',
    ItemTypes = 'itemTypes',
    Geofence = 'geofence',
    MapView = 'mapView',
    Company = 'company',
    Craft = 'craft',
    Form = 'form',
    Rule = 'rule',
    Channel = 'channel',
    Tag = 'tag',
    Badge = 'badge',
    PermissionGroup = 'permissionGroup',
    Walt = 'walt',
    Wilma = 'wilma',
    ActiveUsers = 'activeUsers',
    InvitedUsers = 'invitedUsers',
    RequestedUsers = 'requestedUsers',
    BlockedUsers = 'blockedUsers',
    ImportedUsers = 'importedUsers',
    Classifications = 'classifications',
    Site = 'site',
    TypeBasic = 'typeBasic',
    TypeFull = 'typeFull',
    WispAssigned = 'wispAssigned',
    WispNotAssigned = 'wispNotAssigned',
    BadgeAssigned = 'badgeAssigned',
    BadgeNotAssigned = 'badgeNotAssigned',
}

export const CATEGORY_PARENT_MAP: {[key in TableFilterCategory]?: TableFilterCategory} = {
    [TableFilterCategory.ActiveUsers]: TableFilterCategory.Users,
    [TableFilterCategory.InvitedUsers]: TableFilterCategory.Users,
    [TableFilterCategory.RequestedUsers]: TableFilterCategory.Users,
    [TableFilterCategory.BlockedUsers]: TableFilterCategory.Users,
    [TableFilterCategory.ImportedUsers]: TableFilterCategory.Users,
    [TableFilterCategory.TypeBasic]: TableFilterCategory.Type,
    [TableFilterCategory.TypeFull]: TableFilterCategory.Type,
    [TableFilterCategory.WispAssigned]: TableFilterCategory.Wisps,
    [TableFilterCategory.WispNotAssigned]: TableFilterCategory.Wisps,
    [TableFilterCategory.BadgeAssigned]: TableFilterCategory.Badge,
    [TableFilterCategory.BadgeNotAssigned]: TableFilterCategory.Badge,
};

export interface TableFilter {
    title: string;
    selected: boolean;
    key?: any;
    filterFn(row: TableRow): boolean;
}

export interface TableFilterResult {
    name: string;
    icon?: string | AppIcon;
    key: string;
    category: TableFilterCategory;
    type: FilterResultType;
    selected?: boolean;
    hidden?: boolean;
    setSelected?: (this: TableFilterResult, selected: boolean, original?: boolean, click?: boolean) => any;
    disabled?: (this: TableFilterResult) => boolean;
    appendixText?: (this: TableFilterResult) => string;
    appendixIcon?: AppIcon;
    childCount?: number;
    data?: Company | Craft | Tag;
    children?: {[key: string]: TableFilterResult};
    entities?: {[key: string]: TableFilterResult}; // entities you want to keep track of within category (i.e. people in company)
    filters?: {[key in TableFilterCategory]?: {[id: string]: TableFilterResult}};
    customClickAction?: () => void;
    multiselect?: boolean;
}

export type TableFilterCategoryResults = {[key in TableFilterCategory]?: {[key: string]: TableFilterResult}};
export type TableFilterCategoryToIdKey = {[key in TableFilterCategory]?: { key: string, collection?: boolean }};

export const filterCategroyToTranslationKey: {[key in TableFilterCategory]?: string} = {
    [TableFilterCategory.Companies]: 'shared.person.company',
    [TableFilterCategory.Crafts]: 'shared.person.craft',
    [TableFilterCategory.Tags]: 'shared.person.tags',
    [TableFilterCategory.Sites]: 'shared.person.sites',
    [TableFilterCategory.Users]: 'shared.person.status',
    [TableFilterCategory.Type]: 'shared.person.type',
    [TableFilterCategory.TypeFull]: 'shared.person.full',
    [TableFilterCategory.TypeBasic]: 'shared.person.basic',
    [TableFilterCategory.ActiveUsers]: 'shared.person.active',
    [TableFilterCategory.InvitedUsers]: 'shared.person.invited',
    [TableFilterCategory.RequestedUsers]: 'shared.person.requested',
    [TableFilterCategory.BlockedUsers]: 'shared.person.blocked',
    [TableFilterCategory.ImportedUsers]: 'shared.person.imported',
    [TableFilterCategory.Classifications]: 'shared.geofence.classifications',
    [TableFilterCategory.Wisps]: 'shared.person.wisps',
    [TableFilterCategory.WispAssigned]: 'shared.person.assigned',
    [TableFilterCategory.WispNotAssigned]: 'shared.person.not-assigned',
    [TableFilterCategory.Badge]: 'shared.person.badge',
    [TableFilterCategory.BadgeAssigned]: 'shared.person.assigned',
    [TableFilterCategory.BadgeNotAssigned]: 'shared.person.not-assigned',
};


export const filterCategroyToIcon: {[key in TableFilterCategory]?: string | AppIcon} = {
    [TableFilterCategory.Companies]: 'fas fa-industry ',
    [TableFilterCategory.Crafts]: 'fas fa-tools',
    [TableFilterCategory.Geofences]: 'fas fa-draw-polygon',
    [TableFilterCategory.Tags]: 'fas fa-tags',
    [TableFilterCategory.Sites]: 'fas fa-building',
    [TableFilterCategory.Type]: 'far fa-users',
    [TableFilterCategory.TypeBasic]: 'far fa-user',
    [TableFilterCategory.TypeFull]: 'fas fa-user',
    [TableFilterCategory.Users]: 'fas fa-wave-pulse',
    [TableFilterCategory.ActiveUsers]: 'fas fa-star',
    [TableFilterCategory.InvitedUsers]: 'fas fa-envelope',
    [TableFilterCategory.RequestedUsers]: 'fa-solid fa-star-of-life',
    [TableFilterCategory.BlockedUsers]: 'fas fa-lock',
    [TableFilterCategory.ImportedUsers]: 'fas fa-clock',
    [TableFilterCategory.Wisps]: FEATURE_ICONS.wisp.icon,
    [TableFilterCategory.WispAssigned]: 'fa-solid fa-circle-check',
    [TableFilterCategory.WispNotAssigned]: 'fa-solid fa-circle-xmark',
    [TableFilterCategory.Badge]: FEATURE_ICONS.badge.icon,
    [TableFilterCategory.BadgeAssigned]: 'fa-solid fa-circle-check',
    [TableFilterCategory.BadgeNotAssigned]: 'fa-solid fa-circle-xmark',
};

export const filterCategoryToIdKey: TableFilterCategoryToIdKey = {
    [TableFilterCategory.Classifications]: { key: 'category' },
    [TableFilterCategory.Companies]: { key: 'companyId' },
    [TableFilterCategory.Crafts]: { key: 'crafts' },
    [TableFilterCategory.Tags]: { key: 'tags' },
    [TableFilterCategory.Sites]: { key: 'siteIds' },
    [TableFilterCategory.TypeBasic]: { key: 'id', collection: true },
    [TableFilterCategory.TypeFull]: { key: 'id', collection: true },
    [TableFilterCategory.ActiveUsers]: { key: 'id', collection: true },
    [TableFilterCategory.InvitedUsers]: { key: 'id', collection: true },
    [TableFilterCategory.RequestedUsers]: { key: 'id', collection: true },
    [TableFilterCategory.BlockedUsers]: { key: 'id', collection: true },
    [TableFilterCategory.ImportedUsers]: { key: 'id', collection: true },
    [TableFilterCategory.WispAssigned]: { key: 'id', collection: true },
    [TableFilterCategory.WispNotAssigned]: { key: 'id', collection: true },
    [TableFilterCategory.BadgeAssigned]: { key: 'id', collection: true },
    [TableFilterCategory.BadgeNotAssigned]: { key: 'id', collection: true },
};

export enum RowItemType {
    avatarPerson = 'avatarPerson',
    checkbox = 'checkbox',
    color = 'color',
    date = 'date',
    duration = 'duration',
    icon = 'icon',
    image = 'image',
    number = 'number',
    text = 'text',
    timeAgo = 'timeAgo',
    time = 'time',
    toggle = 'toggle',
    percent = 'percent',
    person = 'person', // avatar - name/crafts - company logo
    folderBack = 'folderBack',
}

export interface TableColumnGroup {
    title: string;
    ids: string[];
}

export interface TableColumn {
    id?: string;
    title: string;
    titlePrefixIcon?: AppIcon;
    overridesSelection?: boolean;
    translate?: boolean;
    colKey: string;
    dataColKey?: string;
    type: RowItemType | ((row: any) => RowItemType);
    sort?: TableColumnSort;
    minWidth?: number;
    maxWidth?: number;
    columnHeadTooltip?: string; // tooltip for column header hover
    hideColumnHeadTooltipWhenFit?: boolean;
    preserveCase?: boolean;
    rowItemNgStyle?: {[key: string]: any};
    maxLines?: number;
    lineExpand?(row:any): any;
    total?(row: any): any;
    clickable?(row: any): boolean;
    class?(row: any): string;
    value?(row: any, col?: TableColumn): any;
    tooltip?(row: any): any; // tooltip for column value hover
    pending?(row: any): boolean;
    prefix?(row: any): AppIcon;
    postfix?(row: any): AppIcon;
    icon?(row: any): RowItemIcon;
}

export interface TableColumnSort {
    sortable?: boolean;
    selected?: boolean;
    sortAsc?: boolean;
    fn?(row: any): any;
}

export interface FolderChange {
    folder: FilterFolder;
    action: FormAction;
}

export enum TableSetting {
    folderTree = 'folderTree',
}

export const commonBulkEdits: { [key: string]: BulkEdit } = {
    delete : {
        title: 'generics.delete',
        icon: 'fas fa-trash',
        action: BulkEditAction.delete,
    },
};

export enum PaginationDirection {
    desc = 'descending',
    asc = 'ascending',
}

export interface Paginator {
    totalItems: number;
    currentPage: number;
    pageSize: number;
    totalPages: number;
    startIndex: number;
    endIndex: number;
    direction: PaginationDirection;
    changedDirection: boolean;
}

export const columnPrefixIconConfig = (icon: AppIcon, bgColor?: string): AppIcon => ({
    ...icon, fontSize: '20px', bgCircle: { bgColor: bgColor ?? css.colors.BLACK, diameter: '35px' },
});
