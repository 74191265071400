import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackBar } from 'weavix-shared/services/alert.service';

@Component({
    selector: 'app-snack-bar',
    templateUrl: './snack-bar.component.html',
})
export class SnackBarComponent {
    public constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: SnackBar,
    ) { }
}
